// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Fira Code', monospace;
  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));
  background-color: #f8f8f8;
  color: #171717;
  line-height: calc(1.5em + (1.6 - 1.5) * ((100vw - 300px)/(1600 - 300)));
  overflow-x: hidden;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,mCAAmC;EACnC,oEAAoE;EACpE,yBAAyB;EACzB,cAAc;EACd,uEAAuE;EACvE,kBAAkB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\nbody {\n  font-family: 'Fira Code', monospace;\n  font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1600 - 300)));\n  background-color: #f8f8f8;\n  color: #171717;\n  line-height: calc(1.5em + (1.6 - 1.5) * ((100vw - 300px)/(1600 - 300)));\n  overflow-x: hidden;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
